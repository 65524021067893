import React from "react"
//import { Link } from "gatsby"

const imgBg1 = require('../../../static/img/bg-showcase-1.jpg');
const imgBg2 = require('../../../static/img/bg-showcase-2.jpg');
const imgBg3 = require('../../../static/img/bg-showcase-3.jpg');

const HomeContent = () => (

  <div id="content">
	{/* Masthead */}
	<header className="masthead text-white text-center">
	  <div className="overlay"></div>
	  <div className="container">
	    <div className="row">
	      <div className="col-xl-9 mx-auto">
	        <h1 className="mb-5">Build a landing page for your business or project and generate more leads!</h1>
	      </div>
	      <div className="col-md-10 col-lg-8 col-xl-7 mx-auto">
	        <form>
	          <div className="form-row">
	            <div className="col-12 col-md-9 mb-2 mb-md-0">
	              <input type="email" className="form-control form-control-lg" placeholder="Enter your email..." />
	            </div>
	            <div className="col-12 col-md-3">
	              <button type="submit" className="btn btn-block btn-lg btn-primary">Sign up!</button>
	            </div>
	          </div>
	        </form>
	      </div>
	    </div>
	  </div>
	</header>

	{/* Icons Grid */}
	<section className="features-icons bg-light text-center">
	  <div className="container">
	    <div className="row">
	      <div className="col-lg-4">
	        <div className="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3">
	          <div className="features-icons-icon d-flex">
	            <i className="icon-screen-desktop m-auto text-primary"></i>
	          </div>
	          <h3>Fully Responsive</h3>
	          <p className="lead mb-0">This theme will look great on any device, no matter the size!</p>
	        </div>
	      </div>
	      <div className="col-lg-4">
	        <div className="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3">
	          <div className="features-icons-icon d-flex">
	            <i className="icon-layers m-auto text-primary"></i>
	          </div>
	          <h3>Bootstrap 4 Ready</h3>
	          <p className="lead mb-0">Featuring the latest build of the new Bootstrap 4 framework!</p>
	        </div>
	      </div>
	      <div className="col-lg-4">
	        <div className="features-icons-item mx-auto mb-0 mb-lg-3">
	          <div className="features-icons-icon d-flex">
	            <i className="icon-check m-auto text-primary"></i>
	          </div>
	          <h3>Easy to Use</h3>
	          <p className="lead mb-0">Ready to use with your own content, or customize the source files!</p>
	        </div>
	      </div>
	    </div>
	  </div>
	</section>

	{/* Image Showcases */}
	<section className="showcase">
	  <div className="container-fluid p-0">
	    <div className="row no-gutters">

	      <div className="col-lg-6 order-lg-2 text-white showcase-img" style={{ backgroundImage: `url(${imgBg1})` }}></div>
	      <div className="col-lg-6 order-lg-1 my-auto showcase-text">
	        <h2>Fully Responsive Design</h2>
	        <p className="lead mb-0">When you use a theme created by Start Bootstrap, you know that the theme will look great on any device, whether it's a phone, tablet, or desktop the page will behave responsively!</p>
	      </div>
	    </div>
	    <div className="row no-gutters">
	      <div className="col-lg-6 text-white showcase-img" style={{ backgroundImage: `url(${imgBg2})` }}></div>
	      <div className="col-lg-6 my-auto showcase-text">
	        <h2>Updated For Bootstrap 4</h2>
	        <p className="lead mb-0">Newly improved, and full of great utility classes, Bootstrap 4 is leading the way in mobile responsive web development! All of the themes on Start Bootstrap are now using Bootstrap 4!</p>
	      </div>
	    </div>
	    <div className="row no-gutters">
	      <div className="col-lg-6 order-lg-2 text-white showcase-img" style={{ backgroundImage: `url(${imgBg3})` }}></div>
	      <div className="col-lg-6 order-lg-1 my-auto showcase-text">
	        <h2>Easy to Use &amp; Customize</h2>
	        <p className="lead mb-0">Landing Page is just HTML and CSS with a splash of SCSS for users who demand some deeper customization options. Out of the box, just add your content and images, and your new landing page will be ready to go!</p>
	      </div>
	    </div>
	  </div>
	</section>

	<section className="recommendations">
		<div className="container">
			<h2 className="mb-5">Recommendations</h2>
			<div className="row text-center">

				<div className="col-lg-3 col-md-6 mb-4">
					<div className="card h-100">
						<img className="card-img-top" src="http://placehold.it/500x325" alt="" />
						<div className="card-body">
						<h4 className="card-title">Card title</h4>
						<p className="card-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sapiente esse necessitatibus neque.</p>
						</div>
						<div className="card-footer">
						<a href="#" className="btn btn-primary">Find Out More!</a>
						</div>
					</div>
				</div>

				<div className="col-lg-3 col-md-6 mb-4">
					<div className="card h-100">
						<img className="card-img-top" src="http://placehold.it/500x325" alt="" />
						<div className="card-body">
						<h4 className="card-title">Card title</h4>
						<p className="card-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Explicabo magni sapiente, tempore debitis beatae culpa natus architecto.</p>
						</div>
						<div className="card-footer">
						<a href="#" className="btn btn-primary">Find Out More!</a>
						</div>
					</div>
				</div>

				<div className="col-lg-3 col-md-6 mb-4">
					<div className="card h-100">
						<img className="card-img-top" src="http://placehold.it/500x325" alt="" />
						<div className="card-body">
						<h4 className="card-title">Card title</h4>
						<p className="card-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sapiente esse necessitatibus neque.</p>
						</div>
						<div className="card-footer">
						<a href="#" className="btn btn-primary">Find Out More!</a>
						</div>
					</div>
				</div>

				<div className="col-lg-3 col-md-6 mb-4">
					<div className="card h-100">
						<img className="card-img-top" src="http://placehold.it/500x325" alt="" />
						<div className="card-body">
						<h4 className="card-title">Card title</h4>
						<p className="card-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Explicabo magni sapiente, tempore debitis beatae culpa natus architecto.</p>
						</div>
						<div className="card-footer">
						<a href="#" className="btn btn-primary">Find Out More!</a>
						</div>
					</div>
				</div>

			</div>
		</div>
	</section>


	{/* Call to Action */ }
	<section className="call-to-action text-white text-center">
	  <div className="overlay"></div>
	  <div className="container">
	    <div className="row">
	      <div className="col-xl-9 mx-auto">
	        <h2 className="mb-4">Ready to get started? Sign up now!</h2>
	      </div>
	      <div className="col-md-10 col-lg-8 col-xl-7 mx-auto">
	        <form>
	          <div className="form-row">
	            <div className="col-12 col-md-9 mb-2 mb-md-0">
	              <input type="email" className="form-control form-control-lg" placeholder="Enter your email..." />
	            </div>
	            <div className="col-12 col-md-3">
	              <button type="submit" className="btn btn-block btn-lg btn-primary">Sign up!</button>
	            </div>
	          </div>
	        </form>
	      </div>
	    </div>
	  </div>
	</section>
  </div>

)

export default HomeContent
