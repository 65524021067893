import React from "react"
import { Link } from "gatsby"

import { FaTwitter,FaFacebookF,FaInstagram } from 'react-icons/fa'


const Footer = () => (

	<footer className="footer bg-light">
	  <div className="container">
	    <div className="row">
	      <div className="col-lg-6 h-100 text-center text-lg-left my-auto">
	        <ul className="list-inline mb-2">
	          <li className="list-inline-item">
	            {/*<a href="#">About</a>*/}
	            <Link to="/about">About</Link>
	          </li>
	          <li className="list-inline-item">&sdot;</li>
	          <li className="list-inline-item">
	            <Link to="/contact">Contact</Link>
	          </li>
	          <li className="list-inline-item">&sdot;</li>
	          <li className="list-inline-item">
	            
	            <Link to="/terms">Terms of Use</Link>
	          </li>
	          <li className="list-inline-item">&sdot;</li>
	          <li className="list-inline-item">
	            <Link to="/privacy">Privacy Policy</Link>
	          </li>
	        </ul>
	        <p className="text-muted small mb-4 mb-lg-0">&copy; Your Website {new Date().getFullYear()}. All Rights Reserved.</p>
	      </div>
	      <div className="col-lg-6 h-100 text-center text-lg-right my-auto">
	        <ul className="list-inline mb-0">
	          <li className="list-inline-item mr-3">
	            <a rel="noopener noreferrer" target="_blank" href="https://www.facebook.com"><FaFacebookF /></a>
	          </li>
	          <li className="list-inline-item mr-3">
	            <a rel="noopener noreferrer" target="_blank" href="https://www.twitter.com"><FaTwitter /></a>
	          </li>
	          <li className="list-inline-item">
	            <a rel="noopener noreferrer" target="_blank" href="https://www.instagram.com"><FaInstagram /></a>
	          </li>
	        </ul>
	      </div>
	    </div>
	  </div>
	</footer>
)

export default Footer
